export const Config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*'
  },

  baseUrl: 'https://api.ec-wish-list.net/',
  // baseUrl: 'https://dev.api.ec-wish-list.net/',
  account: 'account/',
  settings: 'settings',
  report: 'reports',
}