import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { findReport } from '../../redux/actions/reports';

const ActivityReportsPage = ({ payload, fetchData, reports }) => {
  const [submitted, setSubmitted] = useState(false);
  const [notAvailable, setNotAvailable] = useState(false);

  const downloadData = (payload) => {
    setSubmitted(true);
    setNotAvailable(false);
    fetchData(payload);
  }

  useEffect(() => {
    // open the url
    // console.log('reports changed');
    if(reports.status === "success") {
      setSubmitted(false);

      let win = window.open(reports.reportUrl, '_blank');
      win.focus();
    } else if(reports.status !== "waiting" && reports.status !== "requesting") {
      console.log('data not available');
      setNotAvailable(true);
    }
  }, [reports]);

  return(
    <div className="settings-page cf">
      <div className="settings-page__header">

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Download Wish List Data</div>
            </div>
            <div className="named-area__description">
              Download wish list data from all signed in customers who have added products to their wish lists in the last two months. See our posts on some suggestions on how to use Microsoft Excel or Google Sheets with this data for email lists and more!
            </div>
          </div>
          
          <div className="named-area__body">
            <div className="a-card a-card--normal">
              <div className="a-card__paddings">
                <div className="fieldset full-width">
                  <h3>Download Today's Data</h3>
                  {!submitted && <button className="btn btn-primary btn-medium" onClick={() => downloadData(payload)}> Download </button> }
                  {submitted && <button disabled className="btn btn-primary btn-medium" onClick={() => downloadData(payload)}> Download </button> }
                  {notAvailable && <div className="remove-button canonical-status text-default canonical-status--warning">
                    <div className="canonical-status__text">No data available, try again later</div>
                  </div>}
                </div>
              </div>
            </div>

            <div className="cards-row">
              <div className="cards-row__inner">

                <div className="cards-row__item">
                  <div className="a-card a-card--has-hover a-card--compact">
                    <a href="https://athousandapps.com/ecwid/microsoft-excel-examples/" target="_blank">
                      <div className="a-card__paddings">
                        <div className="forward-block forward-block--has-action forward-block--wide">
                          <div className="forward-block__infographics">
                            <div className="forward-block__icon">

                              <svg version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 2289.75 2130"
                                 enable-background="new 0 0 2289.75 2130">
                                <metadata>
                                  <sfw>
                                    <slices></slices>
                                    <sliceSourceBounds  bottomLeftOrigin="true" height="2130" width="2289.75" x="-1147.5" y="-1041"></sliceSourceBounds>
                                  </sfw>
                                </metadata>
                                <path fill="#185C37" d="M1437.75,1011.75L532.5,852v1180.393c0,53.907,43.7,97.607,97.607,97.607l0,0h1562.036
                                  c53.907,0,97.607-43.7,97.607-97.607l0,0V1597.5L1437.75,1011.75z"/>
                                <path fill="#21A366" d="M1437.75,0H630.107C576.2,0,532.5,43.7,532.5,97.607c0,0,0,0,0,0V532.5l905.25,532.5L1917,1224.75
                                  L2289.75,1065V532.5L1437.75,0z"/>
                                <path fill="#107C41" d="M532.5,532.5h905.25V1065H532.5V532.5z"/>
                                <path opacity="0.1" enable-background="new    " d="M1180.393,426H532.5v1331.25h647.893c53.834-0.175,97.432-43.773,97.607-97.607
                                  V523.607C1277.825,469.773,1234.227,426.175,1180.393,426z"/>
                                <path opacity="0.2" enable-background="new    " d="M1127.143,479.25H532.5V1810.5h594.643
                                  c53.834-0.175,97.432-43.773,97.607-97.607V576.857C1224.575,523.023,1180.977,479.425,1127.143,479.25z"/>
                                <path opacity="0.2" enable-background="new    " d="M1127.143,479.25H532.5V1704h594.643c53.834-0.175,97.432-43.773,97.607-97.607
                                  V576.857C1224.575,523.023,1180.977,479.425,1127.143,479.25z"/>
                                <path opacity="0.2" enable-background="new    " d="M1073.893,479.25H532.5V1704h541.393c53.834-0.175,97.432-43.773,97.607-97.607
                                  V576.857C1171.325,523.023,1127.727,479.425,1073.893,479.25z"/>
                                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="203.5132" y1="1729.0183" x2="967.9868" y2="404.9817" gradientTransform="matrix(1 0 0 -1 0 2132)">
                                  <stop  offset="0" style={{'stop-color': '#18884F'}}/>
                                  <stop  offset="0.5" style={{'stop-color': '#117E43' }}/>
                                  <stop  offset="1" style={{'stop-color': '#0B6631'}}/>
                                </linearGradient>
                                <path fill="url(#SVGID_1_)" d="M97.607,479.25h976.285c53.907,0,97.607,43.7,97.607,97.607v976.285
                                  c0,53.907-43.7,97.607-97.607,97.607H97.607C43.7,1650.75,0,1607.05,0,1553.143V576.857C0,522.95,43.7,479.25,97.607,479.25z"/>
                                <path fill="#FFFFFF" d="M302.3,1382.264l205.332-318.169L319.5,747.683h151.336l102.666,202.35
                                  c9.479,19.223,15.975,33.494,19.49,42.919h1.331c6.745-15.336,13.845-30.228,21.3-44.677L725.371,747.79h138.929l-192.925,314.548
                                  L869.2,1382.263H721.378L602.79,1160.158c-5.586-9.45-10.326-19.376-14.164-29.66h-1.757c-3.474,10.075-8.083,19.722-13.739,28.755
                                  l-122.102,223.011H302.3z"/>
                                <path fill="#33C481" d="M2192.143,0H1437.75v532.5h852V97.607C2289.75,43.7,2246.05,0,2192.143,0L2192.143,0z"/>
                                <path fill="#107C41" d="M1437.75,1065h852v532.5h-852V1065z"/>
                              </svg>

                            </div>
                          </div>
                          <div className="forward-block__central">
                            <div className="forward-block__title">Microsoft Excel Examples</div>
                            <div className="forward-block__description"></div>
                            <div className="forward-block__content"></div>
                          </div>
                          <div className="forward-block__arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" focusable="false">
                              <path d="M7.3 8.5L1 14.8c-.4.4-.4 1.1 0 1.6.4.4 1.1.4 1.6 0l6.8-6.8c.7-.7.7-1.5 0-2.2L2.6.6C2.1.2 1.4.2 1 .6c-.4.5-.4 1.2 0 1.6l6.3 6.3z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                  
                      <div className="a-card__paddings" aria-hidden="true" style={{display: 'none'}}></div>
                    </a>
                    
                  </div>
                </div>

                <div className="cards-row__item">

                  <div className="a-card a-card--has-hover a-card--compact">
                    <a href="https://athousandapps.com/ecwid/google-sheets-examples/" target="_blank">

                      <div className="a-card__paddings">

                        <div className="forward-block forward-block--has-action forward-block--wide">
                          <div className="forward-block__infographics">
                            <div className="forward-block__icon">
                              <img src="https://www.ec-wish-list.net/google-sheets.png" style={{'max-width': '36px', 'max-height': '36px'}} />
                            </div>
                          </div>
                          <div className="forward-block__central">
                            <div className="forward-block__title">Google Sheets Examples</div>
                            <div className="forward-block__description"></div>
                            <div className="forward-block__content"></div>
                          </div>
                          <div className="forward-block__arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" focusable="false">
                              <path d="M7.3 8.5L1 14.8c-.4.4-.4 1.1 0 1.6.4.4 1.1.4 1.6 0l6.8-6.8c.7-.7.7-1.5 0-2.2L2.6.6C2.1.2 1.4.2 1 .6c-.4.5-.4 1.2 0 1.6l6.3 6.3z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="a-card__paddings" aria-hidden="true" style={{display: 'none'}}></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  reports: state.reports,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (payload) => dispatch(findReport(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReportsPage);