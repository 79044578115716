import './App.css';

import React from 'react';
import MainPage from './components/MainPage';
import CustomizePage from './components/CustomizePage';
import ActivityReportsPage from './components/ActivityReportsPage';
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.7.0/cdn/');

const App = ({ payload }) => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<MainPage payload={payload} />} />
          <Route path='/customize' element={<CustomizePage payload={payload} />} />
          <Route path='/activity' element={<ActivityReportsPage payload={payload} />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App;
