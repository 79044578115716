import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  FIND_REPORT_REQUESTED,
  FIND_REPORT_SUCCESS,
  FIND_REPORT_FAILURE,
} from '../types';

export const findReportRequested = () => {
  return {
    type: FIND_REPORT_REQUESTED
  }
};

export const findReportSuccess = (report) => {
  return {
    type: FIND_REPORT_SUCCESS,
    payload: report
  }
};

export const findReportFailure = () => {
  return {
    type: FIND_REPORT_FAILURE
  }
};

export const findReport = (ecwidPayload) => {
  return (dispatch) => {
    dispatch(findReportRequested());

    let endPoint = Config.baseUrl + Config.report + '?payload=' + ecwidPayload;
    let headers = Object.assign(Config.headers);

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(findReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(findReportFailure());
      })
  }
}
