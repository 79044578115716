import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { findOrCreate } from '../../redux/actions/accounts';
import { getSettings } from '../../redux/actions/settings';

const MainPage = ({ accountLoad, settingsLoad, payload, accounts, settings }) => {
  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = () => {
    accountLoad(payload);
    settingsLoad(payload);
  };

  if (accounts.status === 'success') {
    return(
      <div className="settings-page cf">
        <div className="settings-page__header">
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Wish Lists</div>
              </div>
              <div className="named-area__description">
              </div>
            </div>
            <div className="named-area__body">
              <div className="cards-row">
                <div className="cards-row__inner">
                  <div className="cards-row__item">
                    <div className="a-card a-card--compact">
                      <div className="a-card__paddings">
                        <Link to={`/customize`}>

                          <div className="forward-block forward-block--has-action forward-block--wide">
                            <div className="forward-block__infographics">
                              <div className="forward-block__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 5.5a2.489 2.489 0 0 0-.5089-1.512c1.0017-1.0012 2.252-1.7536 3.6505-2.1566C7.9842 2.8034 8.9108 3.5 10 3.5s2.0157-.6966 2.3584-1.6686c1.3984.403 2.6488 1.1554 3.6505 2.1566A2.4885 2.4885 0 0 0 15.5 5.5C15.5 6.8807 16.6193 8 18 8c.0878 0 .1746-.0046.2601-.0134A8.5197 8.5197 0 0 1 18.5 10c0 .6936-.0831 1.3679-.2399 2.0133A2.5403 2.5403 0 0 0 18 12c-1.3807 0-2.5 1.1193-2.5 2.5 0 .5682.1895 1.0921.5089 1.5119-1.0017 1.0012-2.2521 1.7536-3.6505 2.1566C12.0157 17.1965 11.0892 16.5 10 16.5s-2.0157.6965-2.3584 1.6685c-1.3984-.403-2.6488-1.1554-3.6505-2.1566A2.4887 2.4887 0 0 0 4.5 14.5C4.5 13.1193 3.3807 12 2 12c-.0878 0-.1746.0045-.2601.0133A8.5204 8.5204 0 0 1 1.5 10c0-.6937.083-1.368.2399-2.0134A2.529 2.529 0 0 0 2 8c1.3807 0 2.5-1.1193 2.5-2.5zm5.5 7c1.3807 0 2.5-1.1193 2.5-2.5S11.3807 7.5 10 7.5 7.5 8.6193 7.5 10s1.1193 2.5 2.5 2.5z"/></svg>
                              </div>
                            </div>

                            <div className="forward-block__central">
                              <div className="forward-block__title">Design Settings</div>
                              <div className="forward-block__description">Change how the button and UI appears for your customers' wishlists.</div>
                              <div className="forward-block__content">
                                Test
                              </div>
                            </div>

                            <div className="forward-block__arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" focusable="false">
                                <path d="M7.3 8.5L1 14.8c-.4.4-.4 1.1 0 1.6.4.4 1.1.4 1.6 0l6.8-6.8c.7-.7.7-1.5 0-2.2L2.6.6C2.1.2 1.4.2 1 .6c-.4.5-.4 1.2 0 1.6l6.3 6.3z"></path>
                              </svg>
                            </div>

                          </div>
                        </Link>

                      </div>
                    </div>
                  </div>

                  <div className="cards-row__item">
                    <div className="a-card a-card--has-hover a-card--compact">
                      <div className="a-card__paddings">
                        <Link to={`/activity`}>
                          <div className="forward-block forward-block--has-action forward-block--wide">
                            <div className="forward-block__infographics">
                              <div className="forward-block__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M19 15v2H3V2c1.1046 0 2 .8954 2 2v11h14z"/><path d="M6 7h3v7H6V7zM10 9h3v5h-3V9zM14 5h3v9h-3V5z"/></svg>
                              </div>
                            </div>
                            <div className="forward-block__central">
                              <div className="forward-block__title">Reports</div>
                              <div className="forward-block__description">See information on how your customers are using wish lists.</div>
                              <div className="forward-block__content"></div>
                            </div>
                            <div className="forward-block__arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" focusable="false">
                                <path d="M7.3 8.5L1 14.8c-.4.4-.4 1.1 0 1.6.4.4 1.1.4 1.6 0l6.8-6.8c.7-.7.7-1.5 0-2.2L2.6.6C2.1.2 1.4.2 1 .6c-.4.5-.4 1.2 0 1.6l6.3 6.3z"></path>
                              </svg>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>

                
              </div>

              <div className="small-text">
                By using the Wish List app, you acknowledge that you have read and agree to A Thousand Apps Inc.’s <a target="_blank" href="https://athousandapps.com/terms-of-service">Terms of Service</a> and <a target="_blank" href="https://athousandapps.com/privacy-policy/">Privacy Notice</a>, both of which govern your use of this service.
              </div>
            
            </div>
          </div>
        </div>
      </div>

    )
  }

  return (
    <div className='settings-page cf'>
      <div className='settings-page__body'>
        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Wish Lists</div>
            </div>
            <div className="named-area__description">
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card a-card--normal">
              <div className="a-card__paddings">
                <div className="feature-element">
                  <div className="feature-element__core">
                    <div className="feature-element__data">
                      <div className="placeholder-titles placeholder-titles--small">
                        <div className="placeholder-blink placeholder-title placeholder-blink--delay2x"></div>
                        <div className="placeholder-subtitle">
                          <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                          <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                          <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                          <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  accountLoad: (payload) => dispatch(findOrCreate(payload)),
  settingsLoad: (payload) => dispatch(getSettings(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage); 