import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SlColorPicker from '@shoelace-style/shoelace/dist/react/color-picker';
import { updateSettings } from '../../redux/actions/settings';

const CustomizePage = ({ payload, accounts, settings, submitSettings }) => {
  const [submitted, setSubmitted] = useState(false);
  const [settingsSaved, setSettingsSaved] = useState(true);

  const [settingsForm, setSettingsForm] = useState(settings);

  console.log(settingsForm);

  const [addToWishListButtonText, setAddToWishListButtonText] = useState(settings['add-to-wish-list-button-text']);
  const [addedToWishListButtonText, setAddedToWishListButtonText] = useState(settings['added-to-wish-list-button-text']);
  const [modalTitle, setModalTitle] = useState(settings['modal-title']);
  const [logInLinkText, setLogInLinkText] = useState(settings['log-in-link-text']);
  const [logInLinkUrl, setLogInLinkUrl] = useState(settings['log-in-link-url']);
  const [loggedInText, setLoggedInText] = useState(settings['logged-in-text']);
  const [addToCartButtonText, setAddToCartButtonText] = useState(settings['add-to-cart-button-text']);
  const [couldNotAddToCartText, setCouldNotAddToCartText] = useState(settings['fail-added-to-cart-text']);
  const [addAllToCartButtonText, setAddAllToCartButtonText] = useState(settings['add-all-to-cart-button-text']);
  const [clearWishListButtonText, setClearWishListButtonText] = useState(settings['clear-wish-list-button-text']);
  const [shareButtonText, setShareButtonText] = useState(settings['share-button-text']);
  const [addedToCartText, setAddedToCartText] = useState(settings['added-to-cart-text']);
  const [emptyWishListText, setEmptyWishListText] = useState(settings['empty-wish-list-text']);

  const [logInToShareLinkText, setLogInToShareLinkText] = useState(settings['log-in-to-share-link-text']);

  const [shareMyWishListText, setShareMyWishListText] = useState(settings['share-my-wish-list-text']);
  const [shareOnSocialMediaText, setShareOnSocialMediaText] = useState(settings['share-on-social-media-text']);
  const [copyText, setCopyText] = useState(settings['copy-text']);
  const [copiedText, setCopiedText] = useState(settings['copied-text']);
  const [shareableLinkText, setShareableLinkText] = useState(settings['shareable-link-text']);
  const [shareUrl, setShareUrl] = useState(settings['share-url']);

  const [wishListButtonColor, setWishListButtonColor] = useState(settings['wish-list-button-color']);
  const [wishListButtonTextColor, setWishListButtonTextColor] = useState(settings['wish-list-button-text-color']);
  const [heartButtonBackgroundColor, setHeartButtonBackgroundColor] = useState(settings['heart-button-background-color']);
  const [heartButtonHeartColor, setHeartButtonHeartColor] = useState(settings['heart-button-heart-color']);
  
  const [addToCartButtonColor, setAddToCartButtonColor] = useState(settings['add-to-cart-button-color']);
  const [addToCartButtonTextColor, setAddToCartButtonTextColor] = useState(settings['add-to-cart-button-text-color']);
  const [removeFromWishListTrashCanColor, setRemoveFromWishListTrashCanColor] = useState(settings['remove-from-wish-list-trash-can-color']);
  const [addAllToCartButtonColor, setAddAllToCartButtonColor] = useState(settings['add-all-to-cart-button-color']);
  const [addAllToCartButtonTextColor, setAddAllToCartButtonTextColor] = useState(settings['add-all-to-cart-button-text-color']);
  const [clearWishListButtonColor, setClearWishListButtonColor] = useState(settings['clear-wish-list-button-color']);
  const [clearWishListButtonTextColor, setClearWishListButtonTextColor] = useState(settings['clear-wish-list-button-text-color']);
  const [shareButtonColor, setShareButtonColor] = useState(settings['share-button-color']);
  const [shareButtonTextColor, setShareButtonTextColor] = useState(settings['share-button-text-color']);

  const [heartCounterBackgroundColor, setHeartCounterBackgroundColor] = useState(settings['heart-counter-background-color']);
  const [heartCounterTextColor, setHeartCounterTextColor] = useState(settings['heart-counter-text-color']);

  const [logInToShareText, setLogInToShareText] = useState(settings['log-in-to-share-text']);

  const [xAxisPosition, setXAxisPosition] = useState(settings['x-axis-position']);
  const [yAxisPosition, setYAxisPosition] = useState(settings['y-axis-position']);

  const [wishListEnabled, setWishListEnabled] = useState(settings['wish-list-enabled'] || false);

  const [confirmClearWishListTitleText, setConfirmClearWishListTitleText] = useState(settings['confirm-clear-wish-list-title-text']);
  const [confirmClearButtonText, setConfirmClearButtonText] = useState(settings['confirm-clear-button-text']);
  const [confirmClearQuestionText, setConfirmClearQuestionText] = useState(settings['confirm-clear-question-text']);

  const [confirmClearButtonBackgroundColor, setConfirmClearButtonBackgroundColor] = useState(settings['confirm-clear-button-background-color']);
  const [confirmClearButtonTextColor, setConfirmClearButtonTextColor] = useState(settings['confirm-clear-button-text-color']);

  const [sharedModalTitle, setSharedModalTitle] = useState(settings['shared-modal-title']);

  const [imageMaximumHeight, setImageMaximumHeight] = useState(settings['image-max-height']);

  useEffect(() => {
    setSubmitted(false);
  }, [settings]);

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const setFieldValue = (setField, event) => {
    let value = event.target.value;

    let updatedSettings = settingsForm;
    updatedSettings[event.target.id] = value;
    setSettingsForm(updatedSettings);
    setField(value);
    setSettingsSaved(false);
  }

  const setNumericFieldValue = (setField, event) => {
    let value = event.target.value.replace(/\D/g,'');

    let updatedSettings = settingsForm;
    updatedSettings[event.target.id] = value;
    setSettingsForm(updatedSettings);
    setField(value);
    setSettingsSaved(false);
  }

  const changeColorValue = (event) => {
    let updatedSettings = settingsForm;
    updatedSettings[event.target.id] = event.target.value;

    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const clearColorValue = (setField, e) => {
    let updatedSettings = settingsForm;
    updatedSettings[e.target.getAttribute('target')] = "";

    setSettingsForm(updatedSettings);
    setField("");
    setSettingsSaved(false);
  }

  const handleSubmit = () => {
    setSubmitted(true);

    submitSettings(payload, settingsForm);
    setSettingsSaved(true);
  }

  const xLayoutChange = (e) => {
    console.log(e.target.value);
    setXAxisPosition(e.target.value);
    let updatedSettings = settingsForm;
    updatedSettings['x-axis-position'] = e.target.value;
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const yLayoutChange = (e) => {
    console.log(e.target.value);
    setYAxisPosition(e.target.value);
    let updatedSettings = settingsForm;
    updatedSettings['y-axis-position'] = e.target.value;
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const handleSwitch = () => {
    let updatedSettings = settingsForm;
    updatedSettings['wish-list-enabled'] = !wishListEnabled;

    setWishListEnabled(!wishListEnabled);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const resetSettings = () => {
    setAddToWishListButtonText('');
    setAddedToWishListButtonText('');
    setModalTitle('');
    setLogInLinkText('');
    setLogInLinkUrl('');
    setLoggedInText('');
    setAddToCartButtonText('');
    setAddAllToCartButtonText('');
    setClearWishListButtonText('');
    setShareButtonText('');
    setAddedToCartText('');
    setEmptyWishListText('');
    setLogInToShareLinkText('');
    setShareMyWishListText('');
    setShareOnSocialMediaText('');
    setLogInToShareText('');
    setCopyText('');
    setCopiedText('');
    setConfirmClearWishListTitleText('');
    setConfirmClearButtonText('');
    setConfirmClearQuestionText('');

    setShareUrl('');

    setWishListButtonColor("");
    setWishListButtonTextColor("");
    setHeartButtonBackgroundColor("");
    setHeartButtonHeartColor("");
    setAddToCartButtonColor("");
    setAddToCartButtonTextColor("");
    setRemoveFromWishListTrashCanColor("");
    setAddAllToCartButtonColor("");
    setAddAllToCartButtonTextColor("");
    setClearWishListButtonColor("");
    setClearWishListButtonTextColor("");
    setShareButtonColor("");
    setShareButtonTextColor("");
    setHeartCounterBackgroundColor("");
    setHeartCounterTextColor("");
    setConfirmClearButtonBackgroundColor("");
    setConfirmClearButtonTextColor("");

    setSharedModalTitle("");
    setImageMaximumHeight("");

    setSettingsForm({});
    setWishListEnabled(false);
    setSettingsSaved(false);
  }

  return(
    <div className="settings-page cf">
      <div className="settings-page__header">

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Enable Wish List</div>
            </div>
            <div className="named-area__description">
              
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--compact">
                <div className="a-card__paddings">
                  <div className="iconable-block iconable-block--hide-in-mobile">
                    <div className="iconable-block__infographics">
                      <span className="iconable-block__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#189dee" viewBox="0 0 20 20" focusable='false'>
                          <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5.71 8.11l-5.95 6.52c-.24.24-.56.37-.87.37-.32 0-.63-.12-.87-.37L4.7 11.55c-.48-.48-.48-1.28 0-1.77a1.21 1.21 0 011.73.01l2.46 2.19 5.08-5.64a1.22 1.22 0 011.73 0c.48.5.48 1.28.01 1.77z"/>
                        </svg>
                      </span>
                    </div>
                    <div className="iconable-block__content">
                      <div className="status-block">
                        <div className="status-block__central">
                          <div className="status-block__header">
                            <span className="status-block__title">
                              Enable Wish List
                            </span>
                          </div>
                          <div className="status-block__content">
                            <p>
                              Enable this switch to activate wish lists for customers of your store.
                            </p>
                          </div>
                        </div>
                        <div className="status-block__primary-action">
                          <label className="checkbox big">
                            { wishListEnabled && <input type="checkbox" checked="true" onClick={() => handleSwitch()}/>}
                            { !wishListEnabled && <input type="checkbox" onClick={() => handleSwitch()}/>}
                            <div><div></div></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="status-block__primary-action">

                    { !submitted && <button className="btn btn-primary btn-medium" onClick={() => handleSubmit()}>Save Settings</button> }
                    { submitted && <button className="btn btn-primary btn-medium btn-loading" disabled /> }

                    { !submitted && <button className="remove-button btn btn-destructive btn-medium" onClick={() => resetSettings()}>Reset Settings</button> }
                    { submitted && <button className="remove-button btn btn-destructive btn-medium btn-loading" disabled /> }

                    { !settingsSaved && <div className="remove-button canonical-status text-default canonical-status--warning">
                        <div className="canonical-status__text">Settings changes have not been saved</div>
                      </div> }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Add to Wish List Button</div>
            </div>
            <div className="named-area__description">
              The "Add to Wish List" button is added under the built-in "Add to Bag" button on a product screen. When a product with the selected options has been added to a user's wish list, the text switches to the "Added to Wish List" button text, the button becomes inactive and has its brightness set to 80%.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>
                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/add-to-wish-list.png" />
                    <img src="https://www.ec-wish-list.net/settings/added-to-wish-list.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Add To Wish List Button Text</div>
                    <div className={ addToWishListButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Add To Wish List Button Text</label>
                      
                      { !submitted && <input id="add-to-wish-list-button-text" type="text" value={addToWishListButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddToWishListButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="add-to-wish-list-button-text" type="text" value={addToWishListButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddToWishListButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Add to Wish List</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Added To Wish List Button Text</div>
                    <div className={ addedToWishListButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Added To Wish List Button Text</label>
                      
                      { !submitted && <input id="added-to-wish-list-button-text" type="text" value={addedToWishListButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddedToWishListButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="added-to-wish-list-button-text" type="text" value={addedToWishListButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddedToWishListButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Added to Wish List</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>
              

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Wish List Button Color</div>
                  <SlColorPicker id="wish-list-button-color" value={wishListButtonColor || ''} onSlChange={(e) => setFieldValue(setWishListButtonColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-wish-list-button-color" target="wish-list-button-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setWishListButtonColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Wish List Button Text Color</div>
                  <SlColorPicker id="wish-list-button-text-color" value={wishListButtonTextColor || ''} onSlChange={(e) => setFieldValue(setWishListButtonTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-wish-list-button-color" target="wish-list-button-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setWishListButtonTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Wish List Button</div>
            </div>
            <div className="named-area__description">
              The wish list button is a floating heart button which brings up the user's wish list in a "pop-up dialog" when clicked.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>

                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/wish-list-button.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Heart Button Background Color</div>
                  <SlColorPicker id="heart-button-background-color" value={heartButtonBackgroundColor || ''} onSlChange={(e) => setFieldValue(setHeartButtonBackgroundColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-heart-button-background-color" target="heart-button-background-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setHeartButtonBackgroundColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Heart Button Heart Color</div>
                  <SlColorPicker id="heart-button-heart-color" value={heartButtonHeartColor || ''} onSlChange={(e) => setFieldValue(setHeartButtonHeartColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-heart-button-heart-color" target="heart-button-heart-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setHeartButtonHeartColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Heart Button Counter Background Color</div>
                  <SlColorPicker id="heart-counter-background-color" value={heartCounterBackgroundColor || ''} onSlChange={(e) => setFieldValue(setHeartCounterBackgroundColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-heart-counter-background-color" target="heart-counter-background-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setHeartCounterBackgroundColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Heart Button Counter Text Color</div>
                  <SlColorPicker id="heart-counter-text-color" value={heartCounterTextColor || ''} onSlChange={(e) => setFieldValue(setHeartCounterTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-heart-counter-text-color" target="heart-counter-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setHeartCounterTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Heart Button X-Axis Position</div>

                  <div className="graphic-radio-group">
                    <div className="graphic-radio-group__items">
                      <div className="graphic-radio graphic-radio--small">
                        <label className="graphic-radio__label">
                          <input 
                            type="radio" 
                            name="x-axis-layout" 
                            value="left" 
                            checked={xAxisPosition === "left"}
                            className="graphic-radio__input" 
                            onChange={ (e) => xLayoutChange(e) }
                          />
                          <div className="graphic-radio__icon">
                            <span className="svg-icon">
                              <sl-icon name="align-start"></sl-icon>
                            </span>
                            <div className="graphic-radio__bg"></div>
                          </div>
                          <span className="graphic-radio__title">Align Left</span>
                        </label>
                      </div>
                      <div className="graphic-radio graphic-radio--small">
                        <label className="graphic-radio__label">
                          <input 
                            type="radio" 
                            name="x-axis-layout" 
                            value="middle" 
                            checked={xAxisPosition === "middle"}
                            className="graphic-radio__input" 
                            onChange={ (e) => xLayoutChange(e) }
                          />
                          <div className="graphic-radio__icon">
                            <span className="svg-icon">
                              <sl-icon name="align-center"></sl-icon>
                            </span>
                            <div className="graphic-radio__bg"></div>
                          </div>
                          <span className="graphic-radio__title">Align Center</span>
                        </label>
                      </div>
                      <div className="graphic-radio graphic-radio--small">
                        <label className="graphic-radio__label">
                          <input 
                            type="radio" 
                            name="x-axis-layout" 
                            value="right" 
                            checked={xAxisPosition === "right"}
                            className="graphic-radio__input" 
                            onChange={ (e) => xLayoutChange(e) }
                          />
                          <div className="graphic-radio__icon">
                            <span className="svg-icon">
                              <sl-icon name="align-end"></sl-icon>
                            </span>
                            <div className="graphic-radio__bg"></div>
                          </div>
                          <span className="graphic-radio__title">Align Right</span>
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Heart Button Y-Axis Position</div>

                  <div className="graphic-radio-group">
                    <div className="graphic-radio-group__items">
                      <div className="graphic-radio graphic-radio--small">
                        <label className="graphic-radio__label">
                          <input 
                            type="radio" 
                            name="y-axis-layout" 
                            value="middle" 
                            className="graphic-radio__input" 
                            checked={yAxisPosition === "middle"}
                            onChange={ (e) => yLayoutChange(e) }
                          />
                          <div className="graphic-radio__icon">
                            <span className="svg-icon">
                              <sl-icon name="align-middle"></sl-icon>
                            </span>
                            <div className="graphic-radio__bg"></div>
                          </div>
                          <span className="graphic-radio__title">Align Middle</span>
                        </label>
                      </div>
                      <div className="graphic-radio graphic-radio--small">
                        <label className="graphic-radio__label">
                          <input 
                            type="radio" 
                            name="y-axis-layout" 
                            value="bottom" 
                            className="graphic-radio__input" 
                            checked={yAxisPosition === "bottom"}
                            onChange={ (e) => yLayoutChange(e) }
                          />
                          <div className="graphic-radio__icon">
                            <span className="svg-icon">
                              <sl-icon name="align-bottom"></sl-icon>
                            </span>
                            <div className="graphic-radio__bg"></div>
                          </div>
                          <span className="graphic-radio__title">Align Bottom</span>
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Dialog Header</div>
            </div>
            <div className="named-area__description">
              When the wish list button is clicked, a "pop-up dialog" containing the user's wish list is displayed. The header section of this dialog has a title ("Wish List"), and a Log in prompt section, which either tells the user to log in, or tells the user what email address has been logged in with right now.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>

                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/log-in-link.png" />
                    <img src="https://www.ec-wish-list.net/settings/logged-in-as.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Modal Title</div>
                    <div className={ modalTitle ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Modal Title</label>
                      
                      { !submitted && <input id="modal-title" type="text" value={modalTitle} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setModalTitle, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="modal-title" type="text" value={modalTitle} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setModalTitle, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Wish List</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Log In Link Text</div>
                    <div className={ logInLinkText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Log In Link Text</label>
                      
                      { !submitted && <input id="log-in-link-text" type="text" value={logInLinkText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLogInLinkText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="log-in-link-text" type="text" value={logInLinkText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLogInLinkText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Log in to view your wish list across devices</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Log In Link Url</div>
                    <div className={ logInLinkUrl ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Log In Link Url</label>
                      
                      { !submitted && <input id="log-in-link-url" type="text" value={logInLinkUrl} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLogInLinkUrl, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="log-in-link-url" type="text" value={logInLinkUrl} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLogInLinkUrl, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder"></div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Logged In Text</div>
                    <div className={ loggedInText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Logged In Text</label>
                      
                      { !submitted && <input id="logged-in-text" type="text" value={loggedInText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLoggedInText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="logged-in-text" type="text" value={loggedInText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLoggedInText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Logged in as: </div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Dialog Card</div>
            </div>
            <div className="named-area__description">
              By default, the card images try to take up as much space as possible without deforming the product's or variation's image. The default maximum height that a product's image could be is 10rem (approximately 10 times a browser's font size, usually about 160px). Replacing this value sets the maximum height of these images to the value entered in pixels (px).
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>
                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/dialog-cards.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Image Maximum Height</div>
                    <div className={ imageMaximumHeight ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Image Maximum Height (px)</label>
                      
                      { !submitted && <input id="image-max-height" type="text" value={imageMaximumHeight} onFocus={focus} onBlur={blur} onChange={(e) => setNumericFieldValue(setImageMaximumHeight, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="image-max-height" type="text" value={imageMaximumHeight} onFocus={focus} onBlur={blur} onChange={(e) => setNumericFieldValue(setImageMaximumHeight, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Image Maximum Height (px)</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Wish List Item Actions</div>
            </div>
            <div className="named-area__description">
              When a user's wish list is displayed in a "pop-up dialog", each product in the wish list has actions that can be taken on them, to either add the product to cart, or remove the product from the wish list.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>

                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/add-to-cart-garbage.png" />
                    <img src="https://www.ec-wish-list.net/settings/added-to-cart.png" />
                    <img src="https://www.ec-wish-list.net/settings/fail-add-to-cart.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Add To Cart Button Text</div>
                    <div className={ addToCartButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Add To Cart Button Text</label>
                      
                      { !submitted && <input id="add-to-cart-button-text" type="text" value={addToCartButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddToCartButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="add-to-cart-button-text" type="text" value={addToCartButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddToCartButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Add to cart</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Added To Cart Text</div>
                    <div className={ addedToCartText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Added To Cart Text</label>
                      
                      { !submitted && <input id="added-to-cart-text" type="text" value={addedToCartText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddedToCartText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="added-to-cart-text" type="text" value={addedToCartText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddedToCartText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Added to cart</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Could Not Add To Cart Text</div>
                    <div className={ couldNotAddToCartText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Could Not Add To Cart Text</label>
                      
                      { !submitted && <input id="fail-added-to-cart-text" type="text" value={couldNotAddToCartText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setCouldNotAddToCartText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="fail-added-to-cart-text" type="text" value={couldNotAddToCartText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setCouldNotAddToCartText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Could not add to cart</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Add to Cart Button Color</div>
                  <SlColorPicker id="add-to-cart-button-color" value={addToCartButtonColor || ''} onSlChange={(e) => setFieldValue(setAddToCartButtonColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-add-to-cart-button-color" target="add-to-cart-button-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setAddToCartButtonColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Add to Cart Button Text Color</div>
                  <SlColorPicker id="add-to-cart-button-text-color" value={addToCartButtonTextColor || ''} onSlChange={(e) => setFieldValue(setAddToCartButtonTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-add-to-cart-button-text-color" target="add-to-cart-button-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setAddToCartButtonTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Remove From Wish List Trash Can Color</div>
                  <SlColorPicker id="remove-from-wish-list-trash-can-color" value={removeFromWishListTrashCanColor || ''} onSlChange={(e) => setFieldValue(setRemoveFromWishListTrashCanColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-remove-from-wish-list-trash-can-color" target="remove-from-wish-list-trash-can-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setRemoveFromWishListTrashCanColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Wish List Actions</div>
            </div>
            <div className="named-area__description">
              The "pop-up dialog" showing a user's wish list has some actions listed below the products in the wish list which deal with the entire wish list. The user can add all products to cart, remove all the products from the wish list, or (if the user is logged in) share the wish list on social media. If the user is not logged in, they will see a link to log in.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>

                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/wish-list-buttons.png" />
                    <img src="https://www.ec-wish-list.net/settings/log-in-to-share.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Add All To Cart Button Text</div>
                    <div className={ addAllToCartButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Add All To Cart Button Text</label>
                      
                      { !submitted && <input id="add-all-to-cart-button-text" type="text" value={addAllToCartButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddAllToCartButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="add-all-to-cart-button-text" type="text" value={addAllToCartButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setAddAllToCartButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Add all to cart</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Clear Wish List Button Text</div>
                    <div className={ clearWishListButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Clear Wish List Button Text</label>
                      
                      { !submitted && <input id="clear-wish-list-button-text" type="text" value={clearWishListButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setClearWishListButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="clear-wish-list-button-text" type="text" value={clearWishListButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setClearWishListButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Clear wish list</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Share Button Text</div>
                    <div className={ shareButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Share Button Text</label>
                      
                      { !submitted && <input id="share-button-text" type="text" value={shareButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="share-button-text" type="text" value={shareButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Share</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Empty Wish List Text</div>
                    <div className={ emptyWishListText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Empty Wish List Text</label>
                      
                      { !submitted && <input id="empty-wish-list-text" type="text" value={emptyWishListText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setEmptyWishListText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="empty-wish-list-text" type="text" value={emptyWishListText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setEmptyWishListText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Add items to your wish list to view them here!</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Log In To Share Text</div>
                    <div className={ logInToShareText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Log In To Share Text</label>
                      
                      { !submitted && <input id="log-in-to-share-link-text" type="text" value={logInToShareText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLogInToShareText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="log-in-to-share-link-text" type="text" value={logInToShareText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setLogInToShareText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Log in to share your wish list</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Add All To Cart Button Color</div>
                  <SlColorPicker id="add-all-to-cart-button-color" value={addAllToCartButtonColor || ''} onSlChange={(e) => setFieldValue(setAddAllToCartButtonColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-add-all-to-cart-button-color" target="add-all-to-cart-button-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setAddAllToCartButtonColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Add All To Cart Button Text Color</div>
                  <SlColorPicker id="add-all-to-cart-button-text-color" value={addAllToCartButtonTextColor || ''} onSlChange={(e) => setFieldValue(setAddAllToCartButtonTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-add-all-to-cart-button-text-color" target="add-all-to-cart-button-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setAddAllToCartButtonTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>
              
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Clear Wish List Button Color</div>
                  <SlColorPicker id="clear-wish-list-button-color" value={clearWishListButtonColor || ''} onSlChange={(e) => setFieldValue(setClearWishListButtonColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-wish-list-button-color" target="clear-wish-list-button-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setClearWishListButtonColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Clear Wish List Button Text Color</div>
                  <SlColorPicker id="clear-wish-list-button-text-color" value={clearWishListButtonTextColor || ''} onSlChange={(e) => setFieldValue(setClearWishListButtonTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-wish-list-button-text-color" target="clear-wish-list-button-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setClearWishListButtonTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Share Button Color</div>
                  <SlColorPicker id="share-button-color" value={shareButtonColor || ''} onSlChange={(e) => setFieldValue(setShareButtonColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-share-button-color" target="share-button-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setShareButtonColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Share Button Text Color</div>
                  <SlColorPicker id="share-button-text-color" value={shareButtonTextColor || ''} onSlChange={(e) => setFieldValue(setShareButtonTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-share-button-text-color" target="share-button-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setShareButtonTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Wish List Share Actions</div>
            </div>
            <div className="named-area__description">
              When a user is logged in, they have the option to share their wish list by social media or with a link. You can modify the URL that a user will be sent to when sharing their wish list. By default, the link will send a user to the product or products page from your store. The Wish List app is loaded on category, cart and product pages, so the shared wish list pop-up dialog can appear on any of those pages.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>
                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/share-wish-list.png" />
                    <img src="https://www.ec-wish-list.net/settings/wish-list-link-copied.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Share My Wish List Text</div>
                    <div className={ shareMyWishListText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Share My Wish List Text</label>
                      
                      { !submitted && <input id="share-my-wish-list-text" type="text" value={shareMyWishListText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareMyWishListText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="share-my-wish-list-text" type="text" value={shareMyWishListText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareMyWishListText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Share My Wish List</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Share on Social Media Text</div>
                    <div className={ shareOnSocialMediaText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Share on Social Media Text</label>
                      
                      { !submitted && <input id="share-on-social-media-text" type="text" value={shareOnSocialMediaText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareOnSocialMediaText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="share-on-social-media-text" type="text" value={shareOnSocialMediaText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareOnSocialMediaText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Share on social media</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Shareable Link Text</div>
                    <div className={ shareableLinkText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Shareable Link Text</label>
                      
                      { !submitted && <input id="shareable-link-text" type="text" value={shareableLinkText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareableLinkText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="shareable-link-text" type="text" value={shareableLinkText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareableLinkText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Shareable link</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Share Wish List Url</div>
                    <div className={ shareUrl ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Share Wish List Url</label>
                      
                      { !submitted && <input id="share-url" type="text" value={shareUrl} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareUrl, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="share-url" type="text" value={shareUrl} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setShareUrl, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Store Products URL</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Copy Text</div>
                    <div className={ copyText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Copy Text</label>
                      
                      { !submitted && <input id="copy-text" type="text" value={copyText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setCopyText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="copy-text" type="text" value={copyText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setCopyText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Copy</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Copied Confirmation Text</div>
                    <div className={ copiedText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Copied Confirmation Text</label>
                      
                      { !submitted && <input id="copied-text" type="text" value={copiedText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setCopiedText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="copied-text" type="text" value={copiedText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setCopiedText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Copied</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Shared Wish List</div>
            </div>
            <div className="named-area__description">
              The "pop-up dialog" for when a customer gets a link to a shared wish list shows mostly the same things that the customer sharing the wish list gets, but with some options hidden, and a different title.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>
                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/shared-wish-list-modal.png" />
                    <img src="https://www.ec-wish-list.net/settings/shared-wish-list-title.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Shared Wish List Title</div>
                    <div className={ sharedModalTitle ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Shared Wish List Title</label>
                      
                      { !submitted && <input id="shared-modal-title" type="text" value={sharedModalTitle} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setSharedModalTitle, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="shared-modal-title" type="text" value={sharedModalTitle} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setSharedModalTitle, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Shared Wish List</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Confirm Clear Pop-up</div>
            </div>
            <div className="named-area__description">
              When a user clicks the "Clear wish list" button in with wish list pop-up, a confirmation pop-up appears to make sure that the user wants to remove all products from their wish list.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <h3>Section Example Images</h3>
                  <div className="settings-image-section">
                    <img src="https://www.ec-wish-list.net/settings/confirm-clear-dialog.png" />
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Confirm Clear Wish List Title</div>
                    <div className={ confirmClearWishListTitleText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Confirm Clear Wish List</label>
                      
                      { !submitted && <input id="confirm-clear-wish-list-title-text" type="text" value={confirmClearWishListTitleText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setConfirmClearWishListTitleText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="confirm-clear-wish-list-title-text" type="text" value={confirmClearWishListTitleText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setConfirmClearWishListTitleText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Confirm Clear Wish List</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Confirm Clear Question Text</div>
                    <div className={ confirmClearQuestionText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Confirm Clear Question Text</label>
                      
                      { !submitted && <input id="confirm-clear-question-text" type="text" value={confirmClearQuestionText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setConfirmClearQuestionText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="confirm-clear-question-text" type="text" value={confirmClearQuestionText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setConfirmClearQuestionText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Do you want to remove all products from your wish list?</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset full-width">
                    <div className="fieldset__title">Confirm Remove Products Button Text</div>
                    <div className={ confirmClearButtonText ? "field field--medium field--filled" : "field field--medium"}>
                      <span className="fieldset__svg-icon"></span>
                      <label className="field__label">Confirm Remove Products Button Text</label>
                      
                      { !submitted && <input id="confirm-clear-button-text" type="text" value={confirmClearButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setConfirmClearButtonText, e)} className="field__input" tabindex="4" maxlength="64" /> }
                      { submitted && <input id="confirm-clear-button-text" type="text" value={confirmClearButtonText} onFocus={focus} onBlur={blur} onChange={(e) => setFieldValue(setConfirmClearButtonText, e)} className="field__input" tabindex="4" maxlength="64" disabled /> }
                      
                      <div className="field__placeholder">Remove all products</div>
                      <span className="field-state--success"></span>
                      <span className="field-state--close"></span>
                    </div>
                    <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                  </div>
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Confirm Clear Button Background Color</div>
                  <SlColorPicker id="confirm-clear-button-background-color" value={confirmClearButtonBackgroundColor || ''} onSlChange={(e) => setFieldValue(setConfirmClearButtonBackgroundColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-confirm-clear-button-background-color" target="confirm-clear-button-background-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setConfirmClearButtonBackgroundColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="fieldset__title">Confirm Clear Button Background Text Color</div>
                  <SlColorPicker id="confirm-clear-button-text-color" value={confirmClearButtonTextColor || ''} onSlChange={(e) => setFieldValue(setConfirmClearButtonTextColor, e)} label="Select a color" disabled={ submitted ? "true" : null }/>
                  { !submitted && <button id="clear-confirm-clear-button-text-color" target="confirm-clear-button-text-color" className="remove-button btn btn-delete btn-medium" onClick={(e) => clearColorValue(setConfirmClearButtonTextColor, e)}>Reset Color</button> }
                  { submitted && <button className="remove-button btn btn-delete btn-medium btn-loading" disabled /> }
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Wish Lists Settings</div>
            </div>
            <div className="named-area__description">
              Save or reset these wish list settings.
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card-stack">

              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="status-block__primary-action">

                    { !submitted && <button className="btn btn-primary btn-medium" onClick={() => handleSubmit()}>Save Settings</button> }
                    { submitted && <button className="btn btn-primary btn-medium btn-loading" disabled /> }

                    { !submitted && <button className="remove-button btn btn-destructive btn-medium" onClick={() => resetSettings()}>Reset Settings</button> }
                    { submitted && <button className="remove-button btn btn-destructive btn-medium btn-loading" disabled /> }

                    { !settingsSaved && <div className="remove-button canonical-status text-default canonical-status--warning">
                        <div className="canonical-status__text">Settings changes have not been saved</div>
                      </div> }

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  submitSettings: (payload, newSettings) => dispatch(updateSettings(payload, newSettings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomizePage);