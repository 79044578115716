import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  FIND_OR_CREATE_REQUESTED,
  FIND_OR_CREATE_SUCCESS,
  FIND_OR_CREATE_FAILURE,
} from '../types';

export const findOrCreateRequested = () => {
  return {
    type: FIND_OR_CREATE_REQUESTED
  }
};

export const findOrCreateSuccess = (account) => {
  return {
    type: FIND_OR_CREATE_SUCCESS,
    payload: account
  }
};

export const findOrCreateFailure = () => {
  return {
    type: FIND_OR_CREATE_FAILURE
  }
};

export const findOrCreate = (ecwidPayload) => {
  return (dispatch) => {
    dispatch(findOrCreateRequested());

    let endPoint = Config.baseUrl + Config.account;
    let headers = Object.assign(Config.headers);

    return Axios.post(endPoint, { 'payload': ecwidPayload } , { headers: headers })
      .then((response) => {
        dispatch(findOrCreateSuccess(response.data));
      })
  }
}
