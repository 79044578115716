import {
  FIND_OR_CREATE_REQUESTED,
  FIND_OR_CREATE_SUCCESS,
  FIND_OR_CREATE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  account: null,
  isFetching: false,
  status: 'waiting',
  errors: null
};

const accounts = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case FIND_OR_CREATE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting'
      });

    case FIND_OR_CREATE_SUCCESS:
      return Object.assign({}, state, {
        account: action.payload.account,
        isFetching: false,
        status: 'success',
        errors: null
      });

    case FIND_OR_CREATE_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        errors: 'error here'
      })

    default:
      return state;
  }
}

export default accounts;
