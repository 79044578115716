export const FIND_OR_CREATE_REQUESTED = 'find_or_create_requested';
export const FIND_OR_CREATE_SUCCESS = 'find_or_create_success';
export const FIND_OR_CREATE_FAILURE = 'find_or_create_failure';

export const SETTINGS_REQUESTED = 'settings_requested';
export const SETTINGS_SUCCESS = 'settings_success';
export const SETTINGS_FAILURE = 'settings_failure';

export const SETTINGS_UPDATE_REQUESTED = 'settings_update_requested';
export const SETTINGS_UPDATE_SUCCESS = 'settings_update_success';
export const SETTINGS_UPDATE_FAILURE = 'settings_update_failure';

export const FIND_REPORT_REQUESTED = 'find_report_requested';
export const FIND_REPORT_SUCCESS = 'find_report_success';
export const FIND_REPORT_FAILURE = 'find_report_failure';

// export const _REQUESTED = '_requested';
// export const _SUCCESS = '_success';
// export const _FAILURE = '_failure';