import {
  FIND_REPORT_REQUESTED,
  FIND_REPORT_SUCCESS,
  FIND_REPORT_FAILURE,
} from '../types';

const INITIAL_STATE = {
  reportUrl: null,
  isFetching: false,
  status: 'waiting',
  errors: null
};

const reports = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case FIND_REPORT_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting'
      });

    case FIND_REPORT_SUCCESS:
      return Object.assign({}, state, {
        reportUrl: action.payload.report_url,
        isFetching: false,
        status: 'success',
        errors: null
      });

    case FIND_REPORT_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'error',
        errors: 'error'
      })

    default:
      return state;
  }
}

export default reports;
